import {
	Dispatch,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { getCurrentQueryParams, serializeQueryParams } from '@creditinfo-ui/utils';
import { VerticalSpacing } from '@creditinfo-ui/atoms';

import { HorizontalInset, TabItem, TabKey, TabsLayout, TabsVariant } from '../types';
import { useFindNextTabKey, useFindPreviousTabKey, useFindTabIndex } from '../hooks';
import { HorizontalTabs } from './HorizontalTabs';
import { VerticalTabs } from './VerticalTabs';

export const TabsDefaultHorizontalInsetContext = createContext<HorizontalInset>('none');

export interface TabsProps {
	activeKey?: TabKey;
	contentVerticalPadding?: VerticalSpacing;
	defaultActiveKey?: TabKey;
	/** Only applies to `horizontal` layout */
	hasInsetShadow?: boolean;
	/** Only applies to `horizontal` layout */
	horizontalInset?: HorizontalInset;
	/** Only applies to `horizontal` layout */
	isTabBarCentered?: boolean;
	layout?: TabsLayout;
	onTabClick?: (key: TabKey) => void;
	queryParam?: string;
	setActiveKey?: Dispatch<SetStateAction<TabKey>>;
	tabs: TabItem[];
	variant?: TabsVariant;
}

export const Tabs = ({
	activeKey: activeKeyProp,
	contentVerticalPadding = 'none',
	defaultActiveKey,
	hasInsetShadow = false,
	horizontalInset: horizontalInsetProp,
	isTabBarCentered = false,
	layout = 'horizontal',
	onTabClick,
	setActiveKey: setActiveKeyProp,
	queryParam,
	tabs,
	variant = 'default',
}: TabsProps) => {
	const [localActiveKey, setLocalActiveKey] = useState(() => {
		const queryParamActiveKey = queryParam ? getCurrentQueryParams()[queryParam] : null;

		return queryParamActiveKey ?? defaultActiveKey ?? tabs[0].key;
	});

	const activeKey = activeKeyProp === undefined ? localActiveKey : activeKeyProp;
	const activeKeyRef = useRef(activeKey);

	useEffect(() => {
		activeKeyRef.current = activeKey;
	}, [activeKey]);

	const setActiveKey = setActiveKeyProp ?? setLocalActiveKey;

	const findTabIndex = useFindTabIndex(tabs);
	const activeIndex = useMemo(() => findTabIndex(activeKey), [activeKey, findTabIndex]);

	const handleTabClick = useCallback(
		(key: TabKey) => {
			onTabClick?.(key);
			setActiveKey(key);

			if (queryParam) {
				window.history.replaceState(
					window.history.state,
					'',
					`${window.location.pathname}?${serializeQueryParams({
						...getCurrentQueryParams(),
						[queryParam]: key,
					})}`
				);
			}
		},
		[onTabClick, queryParam, setActiveKey]
	);

	const findNextTabKey = useFindNextTabKey(tabs);
	const findPreviousTabKey = useFindPreviousTabKey(tabs);

	const next = useCallback(() => {
		setActiveKey(findNextTabKey);
	}, [setActiveKey, findNextTabKey]);

	const previous = useCallback(() => {
		setActiveKey(findPreviousTabKey);
	}, [setActiveKey, findPreviousTabKey]);

	const defaultHorizontalInset = useContext(TabsDefaultHorizontalInsetContext);
	const horizontalInset = horizontalInsetProp ?? defaultHorizontalInset;

	return layout === 'vertical' ? (
		<VerticalTabs
			tabs={tabs}
			next={next}
			previous={previous}
			onTabClick={handleTabClick}
			activeIndex={activeIndex}
			activeKey={activeKey}
			setActiveKey={setActiveKey}
			contentVerticalPadding={contentVerticalPadding}
			variant={variant}
		/>
	) : (
		<HorizontalTabs
			tabs={tabs}
			next={next}
			previous={previous}
			onTabClick={handleTabClick}
			activeIndex={activeIndex}
			activeKey={activeKey}
			setActiveKey={setActiveKey}
			contentVerticalPadding={contentVerticalPadding}
			hasInsetShadow={hasInsetShadow}
			horizontalInset={horizontalInset}
			isTabBarCentered={isTabBarCentered}
			variant={variant}
		/>
	);
};
