import { Color } from '@creditinfo-ui/styles';
import { includes } from 'ramda';

export const iconTypes = [
	'add',
	'addressPhysical',
	'addressPostal',
	'administration',
	'antenna',
	'antennaWaves',
	'approve',
	'arrowLeft',
	'arrowRight',
	'attachment',
	'bell',
	'bigData',
	'billing',
	'bouncedCheques',
	'calendar',
	'cancel',
	'caretDown',
	'caretRight',
	'caretUp',
	'chevronDown',
	'chevronLeft',
	'chevronRight',
	'chevronUp',
	'circle',
	'close',
	'code',
	'columns',
	'columnStatusAscending',
	'columnStatusAscendingFiltered',
	'columnStatusDescending',
	'columnStatusDescendingFiltered',
	'columnStatusFiltered',
	'company',
	'contact',
	'contactInfo',
	'contract',
	'contracts',
	'copy',
	'creditApplications',
	'dataManagement',
	'delete',
	'details',
	'devtools',
	'disable',
	'disableReason_AccountStale',
	'disableReason_BranchDisabled',
	'disableReason_Deleted',
	'disableReason_ManuallyDisabled',
	'disableReason_None',
	'disableReason_NotSpecified',
	'disableReason_SourceDisabled',
	'disableReason_SubscriberDisabled',
	'disableReason_SubscriberExpired',
	'disableReason_TooManyFailed2FAAttempts',
	'disableReason_TooManyFailedCaptchaAttempts',
	'disableReason_TooManyFailedLoginAttempts',
	'disableReason_UserExpired',
	'dispute',
	'dot',
	'download',
	'dropzone',
	'edit',
	'enable',
	'export',
	'exportCsv',
	'external',
	'failure',
	'file',
	'filtering',
	'group',
	'hamburger',
	'history',
	'idGeneration',
	'idNumbers',
	'individual',
	'info',
	'inquiries',
	'instantDecision',
	'internal',
	'kebab',
	'locale',
	'localeSwitcher',
	'locked',
	'loginInfo',
	'matching',
	'messageRead',
	'minus',
	'monitoring',
	'noData',
	'noDataFiltered',
	'notification',
	'pageFirst',
	'pageLast',
	'pageNext',
	'pagePrevious',
	'partialMatch',
	'passwordHide',
	'passwordShow',
	'pause',
	'payments',
	'plus',
	'power',
	'print',
	'remove',
	'report',
	'reportBusinessSummary',
	'reportConsumer',
	'reportConsumerPlus',
	'reportCreditinfo',
	'reportCreditinfoPlus',
	'reportInvolvements',
	'reportNegativeInfo',
	'reportOcb',
	'reportQuickSummary',
	'reportScoring',
	'reportSeeId',
	'reportSkipTrace',
	'reportTelecom',
	'requiredAsterisk',
	'reset',
	'response',
	'restart',
	'resume',
	'rollback',
	'run',
	'scoring',
	'search',
	'searchNotAvailable',
	'selection0',
	'selection100',
	'selection50',
	'send',
	'settings',
	'show',
	'showReport',
	'showReportNotRenderable',
	'showReportSummary',
	'sorting',
	'statistics',
	'statusFailure',
	'statusNotApproved',
	'statusNotAvailable',
	'statusNotSpecified',
	'statusProcessing',
	'statusSuccess',
	'statusUploading',
	'statusWaiting',
	'statusWarning',
	'stop',
	'success',
	'successCircle',
	'supervisoryReport',
	'systemMaintenance',
	'taxRates',
	'toggleOff',
	'toggleOn',
	'trendDown',
	'trendSideways',
	'trendUp',
	'unlock',
	'upload',
	'uploadCancel',
	'user',
	'warning',
] as const;

export type IconType = (typeof iconTypes)[number];

export const getIsIconType = (iconType: any): iconType is IconType =>
	typeof iconType === 'string' && includes(iconType, iconTypes);

// NOTE: Some icons must be flipped in RTL direction, e.g. directional icons (arrows).
// However, a magnifying glass icon shouldn't be flipped, because the handle direction
// is based on the fact that most people are right-handed. This means that we need
// to list all flippable icons manually, rather than just flipping them all.
export const horizontallyFlippableIconTypes = [
	'arrowLeft',
	'arrowRight',
	'caretRight',
	'chevronLeft',
	'chevronRight',
	'contract',
	'contracts',
	'details',
	'loginInfo',
	'pageFirst',
	'pageLast',
	'pageNext',
	'pagePrevious',
	'report',
	'response',
	'resume',
	'run',
	'selection50',
	'send',
	'trendSideways',
];

export const defaultColorsByIconType: Partial<Record<IconType, Color>> = {
	statusFailure: 'danger',
	statusNotApproved: 'danger',
	statusNotAvailable: 'gray500',
	statusNotSpecified: 'gray500',
	statusProcessing: 'warning',
	statusSuccess: 'success',
	statusUploading: 'warning',
	statusWaiting: 'teal',
	statusWarning: 'warning',
};
