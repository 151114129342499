import { Dispatch, SetStateAction } from 'react';
import { head, last } from 'ramda';

import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { VerticalSpacing } from '@creditinfo-ui/atoms';

import { TabContent } from './TabContent';
import { Tab } from './Tab';
import { TabItem, TabKey, TabsVariant } from '../types';

const tabBarStyle = prepareStyle(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export interface VerticalTabsProps {
	activeIndex: number;
	activeKey: TabKey;
	contentVerticalPadding: VerticalSpacing;
	next: () => void;
	onTabClick: (key: TabKey) => void;
	previous: () => void;
	setActiveKey: Dispatch<SetStateAction<TabKey>>;
	tabs: TabItem[];
	variant: TabsVariant;
}

export const VerticalTabs = ({
	activeIndex,
	activeKey,
	contentVerticalPadding,
	next,
	onTabClick,
	previous,
	setActiveKey,
	tabs,
	variant,
}: VerticalTabsProps) => {
	const { applyStyle } = useStyles();

	return (
		<div role="tablist" className={applyStyle(tabBarStyle)}>
			{tabs.map((tab, index) => {
				const { content, key, isAlwaysMounted, verticalPadding } = tab;
				const isActive = activeKey === key;

				return (
					<div key={key}>
						<Tab
							tab={tab}
							index={index}
							variant={variant}
							onTabClick={onTabClick}
							activeKey={activeKey}
							activeIndex={activeIndex}
							layout="vertical"
						/>
						{(isActive || isAlwaysMounted) && (
							<TabContent
								hasInsetShadow={false}
								horizontalInset="none"
								isActive={isActive}
								isFirst={tab === head(tabs)}
								isLast={tab === last(tabs)}
								next={next}
								previous={previous}
								setActiveKey={setActiveKey}
								tabKey={key}
								verticalPadding={verticalPadding ?? contentVerticalPadding}
							>
								{content}
							</TabContent>
						)}
					</div>
				);
			})}
		</div>
	);
};
