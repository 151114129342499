import type * as CSS from 'csstype';
import { useIntl } from 'react-intl';
import { cx } from 'ramda-extension';
import {
	HTMLAttributes,
	ReactNode,
	createContext,
	forwardRef,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	Style,
	mergeStyles,
	prepareStyle,
	prepareStyleFactory,
	useStyles,
} from '@creditinfo-ui/styles';

import { Tooltip } from './Tooltip';
import { m } from '../messages';
import { visuallyHiddenStyle } from '../styles';
import { getElementTotalWidth } from '../utils';
import { Icon, IconProps, IconType, iconSizes } from './Icon';
import { AddonPlacement, AddonWidths } from '../types';
import { TEXT_INPUT_COLOR } from '../constants';

export interface InputWrapperProps extends HTMLAttributes<HTMLDivElement> {
	addon?: ReactNode;
	addonPlacement?: AddonPlacement;
	children: ReactNode;
	customStyle?: Style;
	error?: ReactNode;
	icon?: IconType;
	iconProps?: Partial<IconProps> & { tooltip?: ReactNode };
	isDisabled?: boolean;
	isErrorIndented?: boolean;
	isRequired?: boolean;
	label?: ReactNode;
	labelCustomStyle?: Style<LabelStyleProps>;
	labelFor?: string;
	labelId?: string;
	legend?: ReactNode;
}

interface LabelStyleProps {
	isDisabled: boolean;
}

const rootStyle = prepareStyle(() => ({ position: 'relative' }));
const innerContainerStyle = prepareStyle(() => ({ position: 'relative' }));

const labelStyle = prepareStyle<LabelStyleProps>((utils, { isDisabled }) => ({
	fontSize: utils.fontSizes.base,
	fontWeight: utils.fontWeights.semiBold,
	padding: `0 ${utils.spacings.md}`,
	extend: {
		condition: isDisabled,
		style: { color: utils.colors.gray400 },
	},
}));

const asteriskStyle = prepareStyle(() => ({
	verticalAlign: 'top',
}));

const legendStyle = prepareStyle(utils => ({
	color: utils.colors.gray600,
	flexGrow: 1,
	padding: `0 ${utils.spacings.md}`,
}));

const errorStyle = prepareStyle<{ isIndented: boolean }>((utils, { isIndented }) => ({
	color: utils.colors.danger,
	fontWeight: utils.fontWeights.semiBold,
	extend: {
		condition: isIndented,
		style: {
			padding: `0 ${utils.spacings.md}`,
		},
	},
}));

interface AddonStyleProps {
	color: CSS.Property.Color;
	hasPointerEvents: boolean;
	placement: AddonPlacement;
}

const addonStyle = prepareStyleFactory<AddonStyleProps>(
	(utils, { color, hasPointerEvents, placement }) => ({
		color,
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		extend: [
			{
				condition: !hasPointerEvents,
				style: {
					pointerEvents: 'none',
				},
			},
			{
				condition: placement === 'start',
				style: {
					insetInlineStart: utils.spacings.md,
				},
			},
			{
				condition: placement === 'end',
				style: {
					insetInlineEnd: utils.spacings.sm,
				},
			},
		],
	})
);

const errorLegendWrapperStyle = prepareStyle(utils => ({
	display: 'flex',
	fontSize: utils.fontSizes.caption,
	marginTop: '0.4rem',
}));

export interface InputWrapperContextValue {
	addonWidths?: AddonWidths;
}

export const InputWrapperContext = createContext<InputWrapperContextValue>({});

export const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>(
	(
		{
			addon,
			addonPlacement = 'end',
			children,
			className,
			customStyle,
			error,
			icon,
			iconProps,
			isDisabled = false,
			isErrorIndented = true,
			isRequired = false,
			label,
			labelFor,
			labelId,
			labelCustomStyle,
			legend,
			...otherProps
		}: InputWrapperProps,
		ref
	) => {
		const intl = useIntl();
		const { applyStyle, utils } = useStyles();
		const addonRef = useRef<HTMLDivElement>(null);
		const [addonWidths, setAddonWidths] = useState<AddonWidths>({});

		const iconAddonColor =
			(iconProps?.color ? utils.colors[iconProps.color] : null) ??
			(isDisabled ? utils.colors.gray400 : utils.colors.gray800);

		const mergedIconStyle = mergeStyles([
			addonStyle({
				color: iconAddonColor,
				hasPointerEvents: Boolean(iconProps?.tooltip),
				placement: 'end',
			}),
			iconProps?.customStyle,
		]);

		const iconNode = icon && (
			<Icon isLabeled size="md" type={icon} {...iconProps} customStyle={mergedIconStyle} />
		);

		useLayoutEffect(() => {
			const nextAddonWidths: AddonWidths = {};

			if (addon && addonRef.current) {
				nextAddonWidths[addonPlacement] = `${getElementTotalWidth(addonRef.current) / 10}rem`;
			}

			if (icon) {
				nextAddonWidths.end = iconSizes.md;
			}

			setAddonWidths(nextAddonWidths);
		}, [addon, addonPlacement, icon]);

		const inputWrapperContextValue = useMemo(() => ({ addonWidths }), [addonWidths]);

		return (
			<div
				{...otherProps}
				className={cx(applyStyle([rootStyle, customStyle]), className, 'input-wrapper')}
				ref={ref}
			>
				{label && (
					<label
						className={applyStyle([labelStyle, labelCustomStyle], { isDisabled })}
						id={labelId}
						htmlFor={labelFor}
					>
						{label}
						{isRequired && (
							<>
								<Icon
									color="danger"
									isLabeled
									type="requiredAsterisk"
									customStyle={asteriskStyle}
								/>
								<span className={applyStyle(visuallyHiddenStyle)}>
									{` ${intl.formatMessage(m.required)}`}
								</span>
							</>
						)}
					</label>
				)}
				<div className={applyStyle(innerContainerStyle)}>
					<InputWrapperContext.Provider value={inputWrapperContextValue}>
						{children}
					</InputWrapperContext.Provider>
					{iconNode && iconProps?.tooltip ? (
						<Tooltip tooltip={iconProps.tooltip}>{iconNode}</Tooltip>
					) : (
						iconNode
					)}
					{addon && (
						<div
							ref={addonRef}
							className={applyStyle(
								addonStyle({
									color: isDisabled ? utils.colors.gray400 : TEXT_INPUT_COLOR,
									hasPointerEvents: true,
									placement: addonPlacement,
								})
							)}
						>
							{addon}
						</div>
					)}
				</div>
				{(error || legend) && (
					<div className={applyStyle(errorLegendWrapperStyle)}>
						{error && (
							<div className={applyStyle(errorStyle, { isIndented: isErrorIndented })}>{error}</div>
						)}
						{legend && <div className={applyStyle(legendStyle)}>{legend}</div>}
					</div>
				)}
			</div>
		);
	}
);

InputWrapper.displayName = 'InputWrapper';
