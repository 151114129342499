import { useRef, useCallback } from 'react';
import {
	reduxForm as originalReduxForm,
	isDirty as selectIsFormDirty,
	ConfigProps,
	FormDecorator,
	DecoratedFormProps,
} from 'redux-form';
import { isFunction } from 'ramda-extension';
import { useDispatch, useSelector } from 'react-redux';
import { useUnsavedChangesProducer } from '@ci/unsaved-changes';
import { stopPropagation } from '@ci/utils';

import { focusFirstInvalidField } from './focusFirstInvalidField';
import { saveSubmittedValues } from './submittedValues';
import { prepareFormErrors } from './prepareFormErrors';

interface CustomConfigProps {
	passAllSubmitArgs?: boolean;
	shouldProduceUnsavedChanges?: boolean;
}

export const reduxForm =
	<TFormData extends unknown = {}, TProps = {}, TError = string>({
		onSubmit: onSubmitOption,
		onSubmitFail: onSubmitFailOption,
		submitAsSideEffect = true,
		passAllSubmitArgs: passAllSubmitArgsOption,
		shouldProduceUnsavedChanges = false,
		...otherOptions
	}: Omit<ConfigProps<TFormData, TProps, TError>, 'form'> & {
		form?: string;
	} & CustomConfigProps = {}): FormDecorator<TFormData, TProps, TError> =>
	InnerComponent => {
		const InnerComponentWithOriginalReduxForm = originalReduxForm({
			submitAsSideEffect,
			...otherOptions,
		})(InnerComponent) as any;

		const ReduxForm = ({
			onSubmit: onSubmitProp,
			onSubmitFail: onSubmitFailProp,
			passAllSubmitArgs: passAllSubmitArgsProp,
			...otherProps
		}: DecoratedFormProps<TFormData, TProps, TError> & CustomConfigProps) => {
			const form = (otherProps.form || otherOptions.form)!;
			const wrapperRef = useRef<HTMLDivElement>(null);
			const dispatch = useDispatch();
			const passAllSubmitArgs = passAllSubmitArgsProp ?? passAllSubmitArgsOption ?? false;
			const onSubmit = onSubmitProp ?? onSubmitOption;
			const isFormDirty = useSelector(selectIsFormDirty(form));

			useUnsavedChangesProducer(form, shouldProduceUnsavedChanges && isFormDirty);

			const handleSubmit = useCallback(
				(values, ...args) => {
					dispatch(saveSubmittedValues({ values, formId: form }));

					if (passAllSubmitArgs) {
						return (onSubmit as any)(values, ...args);
					}

					return (onSubmit as any)(values);
				},
				[onSubmit, form]
			);

			const onSubmitFail = onSubmitFailProp ?? onSubmitFailOption;

			const handleSubmitFail = useCallback(
				// eslint-disable-next-line max-params
				(errors, dispatch, submitError, props) => {
					// NOTE: We need to log the error, otherwise it gets swallowed (even non-validation errors).
					console.error('Redux Form `handleSubmitFail` error', submitError);

					const returnValue = isFunction(onSubmitFail)
						? onSubmitFail(errors, dispatch, submitError, props)
						: undefined;

					focusFirstInvalidField(prepareFormErrors(errors), wrapperRef.current!);

					return returnValue;
				},
				[onSubmitFail]
			);

			return (
				<div onSubmit={stopPropagation} ref={wrapperRef}>
					<InnerComponentWithOriginalReduxForm
						// NOTE: onSubmit does not have to be defined. Always passing a function breaks journeys
						onSubmit={onSubmit ? handleSubmit : undefined}
						onSubmitFail={handleSubmitFail}
						{...otherProps}
					/>
				</div>
			);
		};

		// NOTE: Typings of `FormDecorator` contain some weird requirement for a `new` signature
		// which we don't care about.
		return ReduxForm as any;
	};
